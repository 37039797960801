//Navigation Mobile
document.addEventListener('DOMContentLoaded', function() {
  const button = document.querySelector('.show-navigation');
  const ul = document.querySelector('.navigation');
  button.addEventListener('click', function() {
      if (ul.classList.contains('opacity-0')) {
          ul.classList.remove('opacity-0');
          button.classList.remove('bg-svg-menu-blue');
          button.classList.add('bg-svg-menuclose-blue');
      } else {
          ul.classList.add('opacity-0');
          button.classList.remove('bg-svg-menuclose-blue');
          button.classList.add('bg-svg-menu-blue');
      }
  });
});

//Navigation Searchbar
document.addEventListener('DOMContentLoaded', function() {
  const button = document.querySelector('.show-searchbar');
  const form = document.querySelector('.searchbar');
  const input = form.querySelector('input[name="Suchbegriffe"]');

  button.addEventListener('click', function(e) {
    e.stopPropagation();
	form.classList.remove('w-[48px]');
	form.classList.remove('z-[-1]');
    form.classList.add('w-[250px]');
	form.classList.add('z-[30]');
	input.focus();
  });

  document.addEventListener('click', function(e) {
    if (!form.contains(e.target)) {
      form.classList.remove('w-[250px]');
	  form.classList.add('z-[-1]');
	  form.classList.add('w-[48px]');
	  form.classList.remove('z-[30]');
    }
  });

  form.addEventListener('click', function(e) {
    e.stopPropagation();
  });
});

// Contact Sidebar
document.addEventListener('DOMContentLoaded', function() {
	const sidebar = document.querySelector('.contact-sidebar');
	const triggers = document.querySelectorAll('.contact-sidebar-trigger');
	const content = document.querySelector('.contact-sidebar-content');
	let activeTrigger = null;

	triggers.forEach(trigger => {
		trigger.addEventListener('click', function() {
			const target = this.getAttribute('data-target');

			if (activeTrigger !== this) {
				if (activeTrigger !== null) {
					activeTrigger.classList.remove('active');
				}

				this.classList.add('active');
				activeTrigger = this;

				sidebar.classList.add('active');
				content.classList.add('active');

				const phoneContent = content.querySelector('[data-content="phone"]');
				const mailContent = content.querySelector('[data-content="mail"]');

				if (target === 'phone') {
					phoneContent.classList.add('active');
					mailContent.classList.remove('active');
				} else if (target === 'mail') {
					phoneContent.classList.remove('active');
					mailContent.classList.add('active');
				}
			} else {
				// Klick auf bereits aktiven Trigger
				this.classList.remove('active');
				activeTrigger = null;

				sidebar.classList.remove('active');
				content.classList.remove('active');

				const phoneContent = content.querySelector('[data-content="phone"]');
				const mailContent = content.querySelector('[data-content="mail"]');

				phoneContent.classList.remove('active');
				mailContent.classList.remove('active');
			}
		});
	});
});

// Modal
const modals = document.querySelectorAll('[data-modal]');

modals.forEach(function (trigger) {
    trigger.addEventListener('click', function (event) {
        event.preventDefault();
        const modal = document.getElementById(trigger.dataset.modal);
        modal.classList.add('open');
        const exits = modal.querySelectorAll('.modal-exit');
        exits.forEach(function (exit) {
            exit.addEventListener('click', function (event) {
                event.preventDefault();
                const video = modal.querySelector('video');
                if (video) {
                    video.pause();
                    video.currentTime = 0;
                }
                modal.classList.remove('open');
            });
        });
    });
});

// Fading Linkboxes
function updateLinkBoxHeight() {
	const linkBoxes = document.querySelectorAll('a.linkbox');

	linkBoxes.forEach(linkBox => {
		const fadeHeight = linkBox.querySelector('div.linkboxfadeheight');

		if (fadeHeight) {
			const height = fadeHeight.offsetHeight + 7;
			const linkBoxContent = linkBox.querySelector('div.linkboxcontent');
			linkBoxContent.style.bottom = `-${height}px`;
		}
	});
}
updateLinkBoxHeight();
window.addEventListener('resize', updateLinkBoxHeight);

// Konfigurator
document.addEventListener('DOMContentLoaded', function() {
	const buttons = document.querySelectorAll('.button-target-gewerk');
	const levels = document.querySelectorAll('.level');

	buttons.forEach(button => {
		button.addEventListener('click', function() {

			buttons.forEach(btn => btn.classList.remove('active'));
			this.classList.add('active');
			const targetGewerk = this.getAttribute('data-target-gewerk');

			if (targetGewerk == 'Alle Bereiche') {

				levels.forEach(level => {
					level.classList.add('active');
				});

				const seminare = document.querySelectorAll('.seminare li');
				seminare.forEach(seminar => {
					seminar.classList.remove('disabled');
				});


			} else {

				levels.forEach(level => {
					const gewerke = level.getAttribute('data-gewerke').split(', ');
					if (gewerke.includes(targetGewerk)) {
						level.classList.add('active');
					} else {
						level.classList.remove('active');
					}
				});

				const seminare = document.querySelectorAll('.seminare li');
				seminare.forEach(seminar => {
					seminar.classList.add('disabled');
					const gewerke = seminar.getAttribute('data-gewerke').split(', ');
					if (gewerke.includes(targetGewerk)) {
						seminar.classList.remove('disabled');
					}
				});

			}
		});
	});
});

// Searchform
document.addEventListener('DOMContentLoaded', function() {
    var suchformular = document.querySelector('#suchformular');
    if (suchformular) {
        suchformular.addEventListener('reset', function() {
			document.querySelectorAll('input[type="checkbox"]').forEach(function(checkbox) {
				checkbox.checked = false;
				checkbox.removeAttribute('checked');
			});
            document.querySelectorAll('input[type="text"]').forEach(function(textInput) {
                textInput.value = '';
				textInput.setAttribute('value', '');
            });
        });
    }
});

// Carousel Splide
function updatePerPage() {
    var splideElement = document.querySelector('.splide-events');
    if (splideElement) {

		var perPage = 4;
		if (window.innerWidth < 768) {
			perPage = 1;
		} else if (window.innerWidth < 1024) {
			perPage = 2;
		} else if (window.innerWidth > 2000) {
			perPage = 5;
		} else if (window.innerWidth > 4000) {
			perPage = 8;
		}

        var splide = new Splide(splideElement, {
            pagination: false,
            type: 'loop',
            perPage: perPage,
            focus: (window.innerWidth >= 768 ? 'center' : ''),
			perMove: 1,
			updateOnMove: true
        });
        splide.mount();
    }

	var splideElementQuotes = document.querySelector('.splide-quotes');
    if (splideElementQuotes) {
        var splide = new Splide(splideElementQuotes, {
            pagination: false,
            type: 'loop',
            perPage: 1,
            focus: 'center',
            perMove: 1,
            updateOnMove: true,
			arrows: false
        }).mount();

        // Eigene Pfeile verbinden
        document.querySelector('.custom-prev').addEventListener('click', function() {
            splide.go('<'); // Gehe zur vorherigen Slide
        });

        document.querySelector('.custom-next').addEventListener('click', function() {
            splide.go('>'); // Gehe zur nächsten Slide
        });
    }

}
document.addEventListener('DOMContentLoaded', updatePerPage);
window.addEventListener('resize', updatePerPage);

// Parallax Image
var image = document.getElementsByClassName('parallax');
new simpleParallax(image, {
	overflow: true,
	delay: 0.7,
	transition: 'cubic-bezier(0,0,0,1)'
});

// Logo Randomizer
document.addEventListener('DOMContentLoaded', function() {
	const randomLogosContainer = document.querySelector('.random-logos');
	if (randomLogosContainer && randomLogos) {
		const randomLogosImages = randomLogosContainer.querySelectorAll('img');

		function exchangeRandomImage() {
			const randomIndex = Math.floor(Math.random() * randomLogosImages.length);
			const randomImage = randomLogosImages[randomIndex];

			randomImage.classList.add('opacity-0', 'transition-opacity', 'duration-500');

			randomImage.addEventListener('transitionend', function handler() {
				randomImage.removeEventListener('transitionend', handler);

				const oldSrc = randomImage.src;
				randomImage.src = randomLogos[0];

				randomLogos.push(oldSrc);
				randomLogos.shift();

				randomImage.classList.remove('opacity-0');
			}, { once: true });
		}

		setInterval(exchangeRandomImage, 3000);
	}
}) ;